import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />

    <section className="main-section py-5">
      <div className="container">
        <div className="content text-center">
          <div className="row">
            <div className="col-lg-7 mx-auto">
              <h2 className="text-theme-secondary text-uppercase">
                404: Not Found
              </h2>
              <p className="text-lg">
                You just hit a route that doesn&#39;t exist... the sadness.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
)

export default NotFoundPage
